
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    margin: auto;
    padding: 24px;
    background-color: #003359;
    color: white;
    width: 506px;
    height: 500px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: content-box;
    overflow-y:auto;
    border-radius: 10px;
    text-align:center;
  }
  
  
  .modal-content:focus {
      outline: none;
  }
  
  /* Close (X) button */
  .x-button-container {
      display: flex;
      justify-content:flex-end;
  }
  .DistributionPopUpXButton {
      width: 32px;
        height: 32px;
  }
  /* Logo */
  .logo-container {
      display: flex;
      justify-content:center;
      align-items: center;
      gap:32px;
      height:42px;
      margin: 24px 0;
  }
  img#WSIBLogo {
      height:42px;
  }
  img#ForumResearchLogo {
      height:42px;
  }
  
  /* Title */
  
  .DistributionPopUpTitle {
      margin:0;
      font-weight: 500;
      font-size: 32px !important;
      padding: 0px !important;
  }
  
  /* Paragraphs */
  .DistributionPopUpText {
      margin: 1.5em 0;
      font-size:18.4px;
      font-weight:300;
      color: #FFFFFF;
  }
  .DistributionPopUpText2 {
      font-weight: 100;
      color: #FFFFFF;
      font-size: 16px !important;
      margin-top: 24px;
      font-family: "Muli", Arial, Helvetica, sans-serif !important;
  }
  
  /* Buttons */
  .DistributionPopUpButton {
    transition:all 0.1s;
	background-color: white;
	color: #003359;
    border-radius: 48px;
	padding: 0.85em 1.625em;
    font-size: 17.6px !important;
	font-weight: 700;
    width: fit-content;
	height: 48 px;
	gap: 10px;
    margin: 0 auto
  }
  .DistributionPopUpButton2 {
      transition:all 0.2s;
      color: white;
      border-radius: 100px;
      margin: 16px 0;
      font-size: 17.6px !important;
      font-weight: 300;
      text-decoration: 1px underline;
      text-underline-offset: 2px;
  }
  
  .DistributionPopUpButton:hover, a:focus .DistributionPopUpButton, a:active .DistributionPopUpButton {
      background-color: #0D73B8;
      color: white;
  }
  
  a.DistributionPopUpLink {
      text-decoration: none;
  }
  
  
  /* Mobile styles - landscape */
  @media (max-width: 932px) and (max-height: 524px) and (orientation: landscape) {
      .modal-content {
          height: calc(100% - 24px);
          width: calc(100% - 24px);
          box-sizing:border-box;
          font-size:0.85em;
      }
  
  
      img#WSIBLogo {
          height:38px;
      }
      img#ForumResearchLogo {
          height:38px;
      }
      
  }
  
  
  /* Mobile styles - portrait */
  @media (max-width: 524px) {
      /* Modal Content */
      .modal-content {
          margin: auto;
          padding: 16px;
          width: 100%;
          height: 100%;
          box-sizing:border-box;
      }
      /* Logo */
      .logo-container {
          display: flex;
          justify-content:center;
          align-items: center;
          height:38px;
          margin: 20px 0;
      }
      img#WSIBLogo {
          height:38px;
      }
      img#ForumResearchLogo {
          height:38px;
      }
      
      /* Title */
      .DistributionPopUpTitle {
          margin:0;
          font-weight: 500 !important;
          
      }
      
      /* Paragraphs */
      .DistributionPopUpText {
          margin: 16px 0;
          font-size:1.15em;
          font-weight:300
      }
  
  
      /* Buttons */
      .DistributionPopUpButton {
          border-radius: 48px;
          font-size:1.1em;
          font-weight: 700;
      }
      .DistributionPopUpButton2 {
          transition:all 0.2s;
          color: white;
          border-radius: 100px;
          font-size:1.1em;
          font-weight: 300;
          text-decoration: 1px underline;
          text-underline-offset: 2px;
      }
  
      .DistributionPopUpText2 {
          font-size:0.85em;
          margin: 16px 0;
      }
  }
  /* Mobile styles - extra small */
  @media (max-width: 240px) {	
      .modal-content {
          font-size: 0.75em;
          padding:8px;
      }
      .logo-container{
          display:none;
      }
  }