.feedbackLink {
    text-decoration: none;
}
.feedbackButton {
    position: fixed;
    background-color: #003359;
    font-family: "Muli", sans-serif;
    border: 2px solid white; 
    border-radius: 16px;
    right: -40px;
    border-left:0;
    padding:40px 12px 40px 52px; 
    writing-mode:vertical-rl;
    text-orientation:mixed;
    transform: rotate(-180deg);
    font-size: 16px;
    font-weight: normal;
    text-decoration: none;
}
  
.feedbackLabel {
    text-align: center;
    color: white;
}
.feedbackButton:hover, .feedbackLink:focus{
    text-decoration: underline white;
}
@media (min-width: 767px ) {
    .feedbackButton {
        top: unset;
    }
}
@media (max-width: 540px) {
    .feedbackButton {
        border-radius: 16px;
        top: unset;
        right: -24px;
        padding:32px 6px 32px 30px;
    }
}
@media (max-width: 400px) {
    .feedbackButton {
        top: 58px;
    }
}
@media (max-width: 350px) {
    .feedbackButton {
        top: 50px;
    }
}